<template>
  <Badge
    v-if="props.quote?.isPurchased"
    type="success"
    :text="useT('bcd283')"
  />
  <Badge
    v-else-if="props.quote?.isArchived"
    type="neutral"
    :text="useT('bcd298')"
  />
  <Badge
    v-else-if="dayjs().isAfter(props.quote?.expiringAt)"
    type="critical"
    :text="useT('bcd282')"
  />
  <Badge
    v-else
    type="warning"
    :text="useT('bcd284', { value: dayjs(props.quote?.expiringAt).diff(dayjs(), 'days') })"
  />
</template>

<script setup>
const props = defineProps({
  quote: {
    type: Object,
    required: true,
  },
});
</script>
