export const useQuotesStore = defineStore('quotes', {
  state: () => {
    return {
      total: 0,
      currentPage: 1,
      perPage: 40,
      quotes: [],
      sortBy: {
        field: 'createdAt',
        direction: 'desc',
      },
    };
  },

  actions: {
    async fetchQuotes(params) {
      const response = await useApi.get('/quotes', { params }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd262'));
      });

      if (response) {
        this.total = response.total;
        this.quotes = response.results;
      }
    },

    async fetchQuote({ quoteId, workspaceDomain }) {
      const response = await useApi.get(`/quotes/${quoteId}`, {
        params: {
          workspace: workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd262'));
      });

      return response;
    },

    async getReportsAndTransactions() {
      const response = await useApi.get('/quotes/export', {}).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd270'));
      });

      return response;
    },

    async update({ quoteId, body }) {
      const response = await useApi.patch(`/quotes/${quoteId}`, { body }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd261'));
      });

      return response;
    },

    async create(body) {
      const response = await useApi.post('/quotes', { body }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd261'));
      });

      return response;
    },

    async archive({ quoteId, workspaceDomain }) {
      const response = await useApi.post(`/quotes/${quoteId}/archive`, {
        params: {
          workspace: workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd261'));
      });

      return response;
    },

    async unarchive({ quoteId, workspaceDomain }) {
      const response = await useApi.post(`/quotes/${quoteId}/unarchive`, {
        params: {
          workspace: workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd261'));
      });

      return response;
    },

    async getPipedriveDeals() {
      const response = await useApi.get('/quotes/pipedrive/deals').catch((error) => {
        console.error(error);
        useToast.error(useT('bcd261'));
      });

      return response;
    },
  },

  getters: {
  },
});
