export const useProductsStore = defineStore('products', {
  state: () => {
    return {
      prices: null,
      subscriptionConfigs: null,
    };
  },

  actions: {
    async fetchPrices() {
      // NOTE: "in." is a reserved workspace domain name for universal workspace
      const response = await useApi.get(`${useRequestURL().protocol}//in.${useRuntimeConfig().public.interfaceDomain}/api/public/products/prices`).catch(() => {
        useToast.error(useT('bcd270'));
      });

      this.prices = response;

      return response;
    },

    async fetchSubscriptionConfigs() {
      // NOTE: "in." is a reserved workspace domain name for universal workspace
      const response = await useApi.get(`${useRequestURL().protocol}//in.${useRuntimeConfig().public.interfaceDomain}/api/public/products/subscription-configs`).catch(() => {
        useToast.error(useT('bcd270'));
      });

      this.subscriptionConfigs = response;

      return response;
    },
  },

  getters: {
    getProductConfig(state) {
      return (product) => {
        if (product?.category === 'planSubscription') {
          return state.subscriptionConfigs[product?.key];
        }

        if (product?.category === 'planSingleUse') {
          return {
            tier: product?.data?.tier,
            maxCapacity: product?.data?.maxCapacity,
          };
        }

        if (product?.category === 'addon') {
          return {
            name: product?.data?.name,
            billingPeriod: 'monthly', // NOTE: create a addons configs file is there will be more then 3 addons
          };
        }

        return false;
      };
    },
  },
});
