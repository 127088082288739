export default function getProductPriceAmount({ price, quote, discount }) {
  if (!price) {
    console.warn('Missing price');
    return null;
  }

  if (!quote.product) {
    console.warn('Missing product in quote');
    return null;
  }

  if (price && quote.product?.category === 'planSingleUse') {
    price = price * quote.product?.data?.maxCapacity;
  }

  if (discount === 'tenPercent') {
    return price * 0.9;
  } else if (discount === 'twentyPercent') {
    return price * 0.8;
  }

  return price;
}
